import logoEzviz from "../assets/images/logo-ezviz.png";
import logoTecnoSuper from "../assets/images/logo-tecnosuper.png";
import logoSuperCase from "../assets/images/logo-supercase.png";
import logo1Hora from "../assets/images/logo-1hora.png";
import logoBerryFields from "../assets/images/logo-berry.png";

export const allies = [
  {
    id: 1,
    name: "Ezviz",
    logo: logoEzviz,
    website: "https://ezviz.tecnosuper.com.co",
  },
  {
    id: 2,
    name: "Tecno Super",
    logo: logoTecnoSuper,
    website: "https://tecnosuper.co",
  },
  {
    id: 3,
    name: "Super Case",
    logo: logoSuperCase,
    website: "https://supercase.com.co",
  },
  {
    id: 4,
    name: "1Hora",
    logo: logo1Hora,
    website: "https://1hora.tecnosuper.com.co",
  },
  {
    id: 5,
    name: "Berry Fields",
    logo: logoBerryFields,
    website: "https://theberryfields.com",
  },
];
