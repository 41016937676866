export const products = [
  {
    id: "erp",
    icon: "fa-solid fa-cubes",
    title: "Sistemas ERP",
    text: "Administramos todo tu negocio en un solo sistema.",
    status: "Inactivo",
  },
  {
    id: "ecommerce",
    icon: "fa-solid fa-shopping-cart",
    title: "Ecommerce",
    text: "Impulsa tus ventas mediante nuestra plataforma en línea.",
    status: "Inactivo",
  },
  {
    id: "documentos-electronicos",
    icon: "fa-solid fa-file-alt",
    title: "Documentos electrónicos",
    text: "Formaliza tus operaciones y mantén en regla los documentos exigidos.",
    status: "Inactivo",
  },
  {
    id: "transportadoras",
    icon: "fa-solid fa-truck",
    title: "Transportadoras",
    text: "Genera, cotiza y despacha tus productos de forma eficiente.",
    status: "Inactivo",
  },
  {
    id: "pasarelas",
    icon: "fa-solid fa-credit-card",
    title: "Pasarelas de pagos",
    text: "Recauda de forma eficiente tus ingresos asegurando la cartera.",
    status: "Inactivo",
  },
  {
    id: "conexion-wrp",
    icon: "fa-brands fa-whatsapp",
    title: "Conexión a WPP",
    text: "Envía comunicaciones de forma eficiente con la red social más usada del mundo.",
    status: "Inactivo",
  },
];
