import React, { useState, useCallback } from "react";
import "./ProcessSection.css";
import { processes } from "../../data/process";

const ProcessSection = () => {
  const [selectedProcessId, setSelectedProcessId] = useState(processes[0].id);
  const selectedProcess = processes.find(process => process.id === selectedProcessId);

  const handleProcessClick = useCallback((processId) => {
    if (selectedProcessId !== processId) {
      setSelectedProcessId(processId);
    }
  }, [selectedProcessId]);

  return (
    <section className="process-section" id="componente6">
      <div className="process-content">
        <h3>Nuestros procesos</h3>
        <div className="process-container">
          <div className="process-list">
            {processes.map((process) => (
              <div
                key={process.id}
                className={`process-item ${
                  selectedProcessId === process.id ? "selected" : ""
                }`}
                onClick={() => handleProcessClick(process.id)}
              >
                <span className="icon"><i className={process.icon}></i></span>
                <span className="text">{process.title}</span>
              </div>
            ))}
          </div>
          {selectedProcess && (
            <div className="process-details">
              <div className="text-details">
                <p>{selectedProcess.text}</p>
              </div>
              <div className="image-details">
                <img src={selectedProcess.image} alt={selectedProcess.title} loading="lazy" />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(ProcessSection);
