// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allies-section {
  padding: 5px 0px 25px 0px;
  background-color: #000000;
}

.allies-slider {
  margin: auto;
  width: 80%;
}

.allie-slide {
  width: 20%;
  height: 100%;
  outline: none;
  border: none;
}

.allie-slide a {
  display: flex;
  margin: 0px 30px;
}

.slick-track {
  display: flex;
  align-items: center;
}

.allie-slide a img {
  display: block;
  max-width: 65%;
  height: auto;
  margin: auto;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .allies-slider {
    margin: 0px auto;
    width: 90%;
  }
}

@media only screen and (min-width: 1025px) {
  .allies-slider {
    margin: 0px auto;
    width: 85%;
  }
}

@media only screen and (min-width: 1600px) {
  .allies-slider {
    margin: 0px auto;
    width: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/allies-section/AlliesSection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF","sourcesContent":[".allies-section {\n  padding: 5px 0px 25px 0px;\n  background-color: #000000;\n}\n\n.allies-slider {\n  margin: auto;\n  width: 80%;\n}\n\n.allie-slide {\n  width: 20%;\n  height: 100%;\n  outline: none;\n  border: none;\n}\n\n.allie-slide a {\n  display: flex;\n  margin: 0px 30px;\n}\n\n.slick-track {\n  display: flex;\n  align-items: center;\n}\n\n.allie-slide a img {\n  display: block;\n  max-width: 65%;\n  height: auto;\n  margin: auto;\n}\n\n@media only screen and (min-width: 601px) and (max-width: 1024px) {\n  .allies-slider {\n    margin: 0px auto;\n    width: 90%;\n  }\n}\n\n@media only screen and (min-width: 1025px) {\n  .allies-slider {\n    margin: 0px auto;\n    width: 85%;\n  }\n}\n\n@media only screen and (min-width: 1600px) {\n  .allies-slider {\n    margin: 0px auto;\n    width: 80%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
