export const services = [
  {
    id: "webs",
    icon: "fa-solid fa-cubes",
    title: "Desarrollo de sitios web",
    text: "Creamos tu página web de acuerdo con tus requisitos y necesidades.",
    status: "Activo",
  },
  {
    id: "aplicaciones",
    icon: "fa-solid fa-shopping-cart",
    title: "Aplicativos web",
    text: "Desarrollamos aplicaciones para que puedas gestionar un sistema organizacional a medida de tus necesidades.",
    status: "Activo",
  },
  {
    id: "apis",
    icon: "fa-solid fa-file-alt",
    title: "Integraciones de APIs",
    text: "Somos expertos en integrar aplicaciones web con servicios externos, adaptándonos de manera eficiente a cualquier necesidad.",
    status: "Activo",
  },
];
