import React from "react";
import { UnderConstruction } from "../../components/under-construction/UnderConstruction";

export const About = () => {
  return (
    <>
      <UnderConstruction></UnderConstruction>
    </>
  );
};
