import './App.css';
import { Routers } from './routes/Routers';
import { useState, useEffect } from 'react';
import { Loader } from "./components/loader/Loader";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = () => {
      setLoading(false);
    };
    setTimeout(loadContent, 3000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Routers></Routers>
    </>
  );
}

export default App;
