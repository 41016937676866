import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./ProductSection.css";
import { products } from "../../data/products";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ProductSection = () => {
  let sliderRefProduct = useRef(null);

  const next = () => {
    sliderRefProduct.slickNext();
  };

  const previous = () => {
    sliderRefProduct.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: 1,
    arrows: false,
    className: "product-list",
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="product-section" id="componente4">
        <div className="product-content">
          <h3>Nuestros productos</h3>
          <button className="slick-btn prev-btn" onClick={previous}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <Slider
            ref={(slider) => {
              sliderRefProduct = slider;
            }}
            {...settings}
            className="product-list"
          >
            {products.map((product) => {
              return (
                <div className="col-product">
                  <div className="product-item">
                    {product.status === "Activo" ? (
                      <Link className="card" to={`/${product.id}`}>
                        <div className="card-icon">
                          <span className="icon">
                            <i className={product.icon}></i>
                          </span>
                        </div>
                        <div className="card-text">
                          <h4>{product.title}</h4>
                          <p>{product.text}</p>
                        </div>
                      </Link>
                    ) : (
                      <div className="card inactive">
                        <div className="enabled">Próximamente</div>
                        <div className="card-icon">
                          <span className="icon">
                            <i className={product.icon}></i>
                          </span>
                        </div>
                        <div className="card-text">
                          <h4>{product.title}</h4>
                          <p>{product.text}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
          <button className="slick-btn next-btn" onClick={next}>
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </section>
    </>
  );
};
