import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import LogoTipoAsinfy from "../../assets/images/logotipo.svg";

export const Header = () => {
  const [activeSection, setActiveSection] = useState("componente1");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const scrollToSection = (sectionId) => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        setActiveSection(sectionId);
      }
    }, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["componente1", "componente2", "componente3", "componente4", "componente5", "componente6"];
      let foundSection = "componente1";
      sections.forEach(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
            foundSection = sectionId;
          }
        }
      });
      setActiveSection(foundSection);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <div className="header-main">
          <div className="nav-main">
            <div className="brand-container">
              <a href="/">
                <img src={LogoTipoAsinfy} alt="Asincode"></img>
              </a>
            </div>
            <nav className="navbar-container">
              <div className="navbar">
                <ul>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente1")}
                      className={activeSection === "componente1" ? "active" : ""}
                    >
                      Inicio
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente2")}
                      className={activeSection === "componente2" ? "active" : ""}
                    >
                      Descúbrenos
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente3")}
                      className={activeSection === "componente3" ? "active" : ""}
                    >
                      Elígenos
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente4")}
                      className={activeSection === "componente4" ? "active" : ""}
                    >
                      Productos
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente5")}
                      className={activeSection === "componente5" ? "active" : ""}
                    >
                      Servicios
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => scrollToSection("componente6")}
                      className={activeSection === "componente6" ? "active" : ""}
                    >
                      Procesos
                    </button>
                  </li>
                  <li>
                    <Link to="/contactanos" className="link-button">
                      Contáctanos
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="nav-button">
            <button className="btn-open" onClick={handleClick}>
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        <div
          className={`header-mb ${open ? "show" : ""}`}
          onClick={handleClick}
        >
          <nav className="nav-main">
            <div className="nav-button">
              <button className="btn-close" onClick={handleClick}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="navbar">
              <ul>
                <li>
                  <button
                    onClick={() => scrollToSection("componente1")}
                    className={activeSection === "componente1" ? "active" : ""}
                  >
                    Inicio
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("componente2")}
                    className={activeSection === "componente2" ? "active" : ""}
                  >
                    Descúbrenos
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("componente3")}
                    className={activeSection === "componente3" ? "active" : ""}
                  >
                    Elígenos
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("componente4")}
                    className={activeSection === "componente4" ? "active" : ""}
                  >
                    Productos
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("componente5")}
                    className={activeSection === "componente5" ? "active" : ""}
                  >
                    Servicios
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection("componente6")}
                    className={activeSection === "componente6" ? "active" : ""}
                  >
                    Procesos
                  </button>
                </li>
                <li>
                  <Link to="/contactanos" className="link-button">
                    Contáctanos
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};