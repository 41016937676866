import imgAutomatizacion from "../assets/images/img-automatizacion.png";
import imgAcompanamiento from "../assets/images/img-acompanamiento.png";
import imgPersonalizacion from "../assets/images/img-personalizacion.png";

export const reasons = [
  {
    id: 1,
    image: imgAutomatizacion,
    title: "Automatización de procesos",
    text: "Simplifica tu día a día y olvídate de las tareas repetitivas. Con nuestra automatización, disfrutarás de una eficiencia sin precedentes.",
  },
  {
    id: 2,
    image: imgAcompanamiento,
    title: "Acompañamiento continuo",
    text: "Te acompañamos en cada etapa del proceso. Desde la implementación hasta el soporte necesario, tendrás a un experto disponible cuando lo necesites.",
  },
  {
    id: 3,
    image: imgPersonalizacion,
    title: "Personalización a la medida",
    text: "Cada negocio es único, y tu producto también debe serlo. Diseñamos soluciones que se adaptan perfectamente a tus necesidades.",
  },
];
