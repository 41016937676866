import React from "react";
import { HeroSection } from "../../components/hero-section/HeroSection";
import { DiscoverSection } from "../../components/discover-section/DiscoverSection";
import { ReasonSection } from "../../components/reason-section/ReasonSection";
import { ProductSection } from "../../components/product-section/ProductSection";
import { ServiceSection } from "../../components/service-section/ServiceSection";
import ProcessSection from "../../components/process-section/ProcessSection";
import { Footer } from "../../components/footer/Footer";

export const Home = () => {
  return (
    <>
      <div id="componente1">
        <HeroSection />
      </div>
      <div id="componente2">
        <DiscoverSection />
      </div>
      <div id="componente3">
        <ReasonSection />
      </div>
      <div id="componente4">
        <ProductSection />
      </div>
      <div id="componente5">
        <ServiceSection />
      </div>
      <div id="componente6">
        <ProcessSection />
      </div>
      <Footer />
    </>
  );
};


