import imgProcessAcercamiento from "../assets/images/img-process-acercamiento.png";
import imgProcessNegociacion from "../assets/images/img-process-negociacion.png";
import imgProcessIntegracion from "../assets/images/img-process-integracion.png";
import imgProcessSoporte from "../assets/images/img-process-soporte.png";

export const processes = [
  {
    id: 1,
    icon: "fa-solid fa-magnifying-glass",
    title: "Acercamiento",
    text: "Estamos contentos de que estés interesado en mejorar tus resultados, de forma estratégica, siempre estaremos dispuestos a asesorarte de la mejor manera.",
    image: imgProcessAcercamiento,
  },
  {
    id: 2,
    icon: "fa-solid fa-handshake",
    title: "Negociación",
    text: "Haz iniciado el primer paso para formar parte del ecosistema Asincode, esperamos que la opción que elijas sea la más idónea para tu negocio.",
    image: imgProcessNegociacion,
  },
  {
    id: 3,
    icon: "fa-solid fa-gears",
    title: "Integración",
    text: "Hemos llegado a la etapa donde cubriremos tus necesidades, integrando de manera eficiente y confiable el producto o servicio acordado.",
    image: imgProcessIntegracion,
  },
  {
    id: 4,
    icon: "fa-solid fa-headset",
    title: "Soporte",
    text: "Estamos dispuestos a orientarte en tu elección, con apoyo integral de parte de todo nuestro equipo Asincode.",
    image: imgProcessSoporte,
  },
];
